import * as Cookies from "js-cookie";
import "remodal/dist/remodal.css";
import "remodal/dist/remodal-default-theme.css";
import "remodal";

$(function() {
  //▼ 歯科医療従事者確認モーダルの表示
  var doctorConfirmModal = $("[data-remodal-id=doctor-confirm]").remodal();
  if (Cookies.get("yes_doctor") !== "yes") {
    doctorConfirmModal.open();
  }
  $(document).on(
    "confirmation",
    "[data-remodal-id=doctor-confirm]",
    function() {
      Cookies.set("yes_doctor", "yes", {
        expires: 31,
      });
    }
  );
  $(document).on(
    "cancellation",
    "[data-remodal-id=doctor-confirm]",
    function() {
      window.location.href =
        "https://doctorbook.jp/?utm_source=academy&utm_medium=ownedmedia";
    }
  );
  //▼ Facebookログインモーダルの表示
  var facebookLoginModal = $("[data-remodal-id=facebook-login]").remodal();
  $(document).on("click", "#facebook-login", function(e) {
    e.preventDefault();
    facebookLoginModal.open();
  });
  $(document).on(
    "confirmation",
    "[data-remodal-id=facebook-login]",
    function() {
      window.location.href = "/auth/facebook";
    }
  );
  $(document).on(
    "cancellation",
    "[data-remodal-id=facebook-login]",
    function() {
      facebookLoginModal.close();
    }
  );
  //▼ GMOカード入力モーダルの表示
  var gmoModal = $("[data-remodal-id=gmo-modal]").remodal();
  $(document).on("click", "#gmo_modal_open_btn", function() {
    gmoModal.open();
  });
  //▼ J&Jモーダルの表示
  var JJConfirmModal = $("[data-remodal-id=JJ-options]").remodal();
  let isShowAgain = false;

  if (Cookies.get("J&J") !== "accept" && Cookies.get("yes_doctor") === "yes") {
    if (!$("[data-remodal-id=JJ-options]").length) return;
    $("[data-remodal-id=JJ-options]")
      .remodal()
      .open();
  }

  $(".JJ_cancel_option").on("click", () => {
    JJConfirmModal.close();
    Cookies.set("J&J", "accept");
    isShowAgain = true;
  });

  $(".JJ_option").on("click", () => {
    $(".remodal-overlay, .JJ_cancel_option, .JJ_option").css("display", "none");
    $(".JJ_modal").css("visibility", "hidden");
    JJConfirmModal.close();
    Cookies.set("J&J", "accept");
    isShowAgain = true;
  });

  $(document).on("closed", "[data-remodal-id=doctor-confirm]", function(e) {
    if (Cookies.get("J&J") !== "accept" && e.reason !== "cancellation") {
    if (!$("[data-remodal-id=JJ-options]").length) return;
      $("[data-remodal-id=JJ-options]")
        .remodal()
        .open();
    }
  });

  $(".JJ_close_icon").on("click", () => {
    Cookies.set("J&J", "accept", { expires: 14 });
    isShowAgain = true;
    JJConfirmModal.close();
  });

  $(document).on("closed", ".JJ_modal", function() {
    if (!isShowAgain) Cookies.set("J&J", "accept", { expires: 14 });
  });
});

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require.context('../images/front', true);

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '../styles/front/main'

import '../scripts/front/script'
import '../scripts/front/learning'
import '../scripts/front/favorite'
import '../scripts/front/favorite_live'
import '../scripts/front/organization_member'
import '../scripts/front/maker'
import '../scripts/front/casebook'
import '../scripts/front/validation'
import '../scripts/front/user_application'
import '../scripts/front/remodal'
import '../scripts/front/events_form'
import '../scripts/front/live_contents_form'
import '../scripts/front/swiper'
import '../scripts/front/movie'
import '../scripts/front/common_header'
import '../scripts/front/category_search'
import '../scripts/front/content_search'
import '../scripts/front/like'

import Rails from 'rails-ujs';
Rails.start();


import Vue from 'vue'
import FrontModal from "../components/front/modal.vue"
import SelectHospital from "../components/front/select_hospital.vue"


$(function() {
  if( $("#app").length > 0 ){
    const props = $("#app").data("props");
    new Vue({
      el: '#app',
      components: {
        FrontModal,
        SelectHospital,
      },
      data: function() {
        return props ? props : {}
      },
    });
  }
});

/* 絞り込み検索 */
$(function(){
    /* PC用 */
    if (window.matchMedia("(min-width: 668px)").matches) {
        // 簡易絞込を開閉する
        $("#search_program_form .search_movie_box_tag dt").on("click", function(e) {
            e.stopPropagation();
            $(this).next().slideToggle(300);
            $(this).toggleClass("active");
        });
    }
    /* スマホ用 */
    else {
        //SPのみ要素の位置変更
        $(".search_movie").prependTo(".learning_main");
        // 詳細絞込
        $(function() {
            var scrollPos;
            // 詳細絞込モーダルを開く
            $("#search_program_form .search_movie_box_btn_more").on("click", function() {
                $(".search_movie_detail_body").css("height", window.innerHeight - 126);
                scrollPos = $(window).scrollTop();
                $("html").addClass("is_fixed");
                $(".search_movie_detail_wrap").fadeToggle(300);
                $(this).toggleClass("active");
            });
            // 詳細絞込モーダルを閉じる
            $("#search_program_form .search_movie_detail_btn_close").click(function () {
                $("html").removeClass("is_fixed");
                $(".search_movie_detail_wrap").fadeOut();
                $(".search_movie_box_btn_more").removeClass("active");
                $(window).scrollTop(scrollPos);
            });
        });
    }

    /* PC/スマホ共通 */
    // チェックボックスがチェックされた時
    $('#search_program_form input[type="checkbox"]').on('click', function(){
        // チェックボックスのチェック状態の同期をとる
        var name = $(this).attr('id');
        if (name.indexOf('_simple_sp') !== -1) {
            name = name.replace('_simple_sp', '');
        }
        if (name.indexOf('_simple') !== -1) {
            name = name.replace('_simple', '');
        }
        $('#' + name).prop('checked', $(this).prop('checked'));
        $('#' + name + '_simple').prop('checked', $(this).prop('checked'));
        $('#' + name + '_simple_sp').prop('checked', $(this).prop('checked'));
    });
    // すべてをクリアするをクリックした時
    $('#search_program_form .search_movie_box_allclear, #search_program_form .search_movie_detail_btn_clear').on('click', function(e){
        e.preventDefault();
        $('#keyword_pc, #keyword_sp').val('');
        $('input[type="checkbox"]:checked').prop('checked', false);
    });
});

/* プログラム動画一覧 受講状況 SPのみ要素の位置変更 */
$(function(){
    if (window.matchMedia("(max-width: 667px)").matches) {
        $(".learning_progress").prependTo(".learning_main");
    }
});

/* プログラム動画一覧 説明文開閉 */
$(window).on("load", function () {
    var itemHeights = [];
    var returnHeight;

    $(function(){
      $(".learning_program_description_toggle").each(function(){
        var thisHeight = $(this).height();
        itemHeights.push(thisHeight);
        $(this).addClass("is-hide");
        returnHeight = $(this).height();
      });
    });

    $(".learning_program_description_btn").click(function(){
      if(!$(this).hasClass("is-show")) {
        var index = $(this).index(".learning_program_description_btn");
        var addHeight = itemHeights[index] + 35;
        $(this).addClass("is-show").next().animate({height: addHeight},500).removeClass("is-hide");
      } else {
        $(this).removeClass("is-show").next().animate({height: returnHeight},500).addClass("is-hide");
      }
    });
});
